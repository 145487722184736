import { Routes, Route, Navigate } from 'react-router-dom';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import { appRoles } from './authConfig';
import { Login } from './pages/Login';
import { PageLayout } from './components/PageLayout';
import { RouteGuard } from './components/RouteGuard';
import { SegmentationResults } from './pages/SegmentationResults';
import { SingleImageView } from './pages/SingleImageView';

import './styles/App.css';


const AuthenticatedPages = () => {
    return (
        <PageLayout>
            <Routes>
                <Route path="/" element={<Navigate to="/iris/segmentationresults" replace />} />
                <Route
                    path="/iris/segmentationresults"
                    element={
                        <RouteGuard roles={[appRoles.IrisUser, appRoles.Admin]}>
                            <SegmentationResults />
                        </RouteGuard>
                    }
                />
                <Route
                    path="/iris/imageresult"
                    element={
                        <RouteGuard roles={[appRoles.IrisUser, appRoles.Admin]}>
                            <SingleImageView />
                        </RouteGuard>
                    }
                />
                <Route path="*" element={<Navigate to="/iris/segmentationresults" replace />} />
            </Routes>
        </PageLayout>
    );
};

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <AuthenticatedTemplate>
                <AuthenticatedPages />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </MsalProvider>
    );
};

export default App;