import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const LabResult = ({ name, min, max, actual, normalRange }) => {
  const calculatePosition = (value) => {
    return ((value - min) / (max - min)) * 100;
  };

  return (
    <div>
      <Typography 
        sx={{ 
          mt: 3, 
          mb: 5, 
          fontFamily: "Open Sans", 
          fontSize: '14px', 
          fontStyle: 'normal', 
          fontWeight: '600', 
          lineHeight: '20px', 
          color: '#F9F9F9'
        }}
      >
        {name}
      </Typography>
      <Box sx={{ position: 'relative', mb: 1.5, height: 40 }}>
        {/* Thin background line */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            width: '100%',
            height: 5,
            backgroundColor: '#F9F9F9',
            borderRadius: 5,
            transform: 'translateY(-50%)',
            zIndex: 1
          }}
        />
        {/* Thicker normal range line */}
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: `${calculatePosition(normalRange[0])}%`,
            width: `${calculatePosition(normalRange[1]) - calculatePosition(normalRange[0])}%`,
            height: 25,
            backgroundColor: '#50B02E',
            transform: 'translateY(-50%)',
            zIndex: 2
          }}
        />
        {/* Vertical lines at the ends */}
        <Box
          sx={{
            position: 'absolute',
            top: '12.5%',
            left: 0,
            width: 2,
            height: '75%',
            backgroundColor: '#F9F9F9',
            zIndex: 3
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            top: '12.5%',
            right: 0,
            width: 2,
            height: '75%',
            backgroundColor: '#F9F9F9',
            zIndex: 3
          }}
        />
        {/* Position labels */}
        <Typography
          sx={{
            position: 'absolute',
            top: 40,
            left: `${calculatePosition(min)}%`,
            transform: 'translateX(-50%)',
            color: '#F9F9F9',
            zIndex: 3,
            fontFamily: "Open Sans",
            fontSize: '14px',
            fontStyle: 'normal', 
            fontWeight: '600', 
            lineHeight: 'normal'
          }}
        >
          {min}
        </Typography>
        <Typography
          sx={{
            position: 'absolute',
            top: 40,
            left: `${calculatePosition(normalRange[0])}%`,
            transform: 'translateX(-50%)',
            color: '#F9F9F9',
            zIndex: 3,
            fontFamily: "Open Sans",
            fontSize: '14px',
            fontStyle: 'normal', 
            fontWeight: '600', 
            lineHeight: 'normal'
          }}
        >
          {normalRange[0]}
        </Typography>
        <Typography
          sx={{
            position: 'absolute',
            top: 40,
            left: `${calculatePosition(normalRange[1])}%`,
            transform: 'translateX(-50%)',
            color: '#F9F9F9',
            zIndex: 3,
            fontFamily: "Open Sans",
            fontSize: '14px',
            fontStyle: 'normal', 
            fontWeight: '600', 
            lineHeight: 'normal'
          }}
        >
          {normalRange[1]}
        </Typography>
        <Typography
          sx={{
            position: 'absolute',
            top: 40,
            left: `${calculatePosition(max)}%`,
            transform: 'translateX(-50%)',
            color: '#F9F9F9',
            zIndex: 3,
            fontFamily: "Open Sans",
            fontSize: '14px',
            fontStyle: 'normal', 
            fontWeight: '600', 
            lineHeight: 'normal'
          }}
        >
          {max}
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            top: -35,
            left: `${calculatePosition(actual)}%`,
            transform: 'translateX(-50%)',
            zIndex: 4,
            p: 1,
            backgroundColor: '#0066CC',
            borderRadius: 1,
            border: '1px solid #0066CC',
            color: '#F9F9F9',
            fontFamily: "Open Sans",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            whiteSpace: 'nowrap',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              borderWidth: '8px',
              borderStyle: 'solid',
              borderColor: '#333 transparent transparent transparent',
              borderTopColor: '#0066CC',
            },
          }}
        >
          {actual}
        </Box>
      </Box>
      </div>
  );
};

export default LabResult;
