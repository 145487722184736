import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useMsal } from '@azure/msal-react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { SegmentationResults } from './SegmentationResults';
import { loginRequest } from "../authConfig";
import exactech_logo from "../images/Exactech_e.svg";
import '../styles/App.css';


export const Login = () => {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <>
      <AuthenticatedTemplate>
        <SegmentationResults />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            bgcolor="#222222"
          >
            <Card sx={{
              bgcolor: '#3B3B3B',
              width: '400px',
              maxWidth: '90%',
              padding: '40px',
            }}>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                  <img src={exactech_logo} alt="Logo" style={{ width: 80, height: 80 }} />
                  <Typography
                    variant="h4"
                    sx={{
                      color: '#F9F9F9',
                      fontFamily: '"Open Sans", sans-serif',
                      fontSize: '32px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      mt: 2
                    }}
                  >
                    Project Orchard
                  </Typography>
                  <Typography
                    sx={{
                      color: '#F9F9F9',
                      fontFamily: "Open Sans",
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: 'normal',
                      mt: 2
                    }}
                  >
                    Login to your Account
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={handleLoginRedirect}
                    sx={{
                      mt: 4,
                      backgroundColor: '#0066CC',
                      '&:hover': {
                        backgroundColor: '#0055AA',
                      },
                      color: '#F9F9F9',
                      textAlign: 'center',
                      fontFamily: '"Open Sans", sans-serif',
                      fontSize: '20px',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '20px',
                      textTransform: 'none',
                      padding: '15px 100px', 
                    }}
                  >
                    Sign In
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
      </UnauthenticatedTemplate>
    </>
  );
};