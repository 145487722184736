import React from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

const ImageLabelsDialog = ({ open, onClose, image }) => {
  const bones = ['scapula', 'clavicle', 'humerus'];
  const muscles = ['deltoid', 'infraspinatus', 'supraspinatus', 'subscapularis'];

  const getLabelChip = (feedback) => {
    const chipProps = {
      'No Correction': { icon: <DoneIcon />, color: "success" },
      'Minor': { icon: <DoneIcon />, color: "secondary" },
      'Major': { icon: <CloseIcon />, color: "error" }
    };

    return (
      <Chip 
        icon={chipProps[feedback].icon} 
        color={chipProps[feedback].color} 
        label={feedback.toUpperCase()} 
        sx={{ mr: 2 }}
      />
    );
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const renderGroup = (title, organs) => {
    return (
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#2C3E50', height: '100%' }}>
          <Typography variant="h5" gutterBottom sx={{ borderBottom: '1px solid #4A6572', paddingBottom: '8px' }}>
            {title}
          </Typography>
          <Grid container spacing={2}>
            {organs.map(organ => {
              const feedbackItem = image && image.feedback 
                ? image.feedback.find(item => item.muscle.toLowerCase() === organ.toLowerCase())
                : null;
  
              return (
                <Grid item xs={12} sm={6} key={organ}>
                  <Paper elevation={2} sx={{ padding: '12px', backgroundColor: '#34495E' }}>
                    <Typography variant="subtitle1" gutterBottom>
                      {capitalizeFirstLetter(organ)}
                    </Typography>
                    <Typography variant="body2">
                      {feedbackItem ? getLabelChip(feedbackItem.feedback) : 'Not Labelled'}
                    </Typography>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </Grid>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ color: '#F9F9F9', backgroundColor: '#1E2A32', fontSize: '1.5rem', fontWeight: 'bold' }}>
        Labels
      </DialogTitle>
      <DialogContent sx={{ color: '#F9F9F9', backgroundColor: '#1E2A32', padding: '24px' }}>
        {image && image.feedback ? (
          <Grid container spacing={3}>
            {renderGroup('Bone', bones)}
            {renderGroup('Muscle', muscles)}
          </Grid>
        ) : (
          <Typography variant="body1" align="center">
            No feedback data available for this image.
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ color: '#F9F9F9', backgroundColor: '#1E2A32', padding: '16px' }}>
        <Button variant="contained" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageLabelsDialog;