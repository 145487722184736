import React from 'react';
import { Link } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { loginRequest } from "../authConfig";
import '../styles/App.css';
import { Typography } from '@mui/material';

const exactech_logo = require("../images/exactech_logo.png")

export default function Navigation() {
  const { instance } = useMsal();
  let activeAccount;

  if (instance) {
      activeAccount = instance.getActiveAccount();
  }

  const [PAnchorEl, setPAnchorEl] = React.useState(null);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  
  const handleClose = () => {
    setPAnchorEl(null);
    setStandardAnchorEl(null);
  };

  const handlePClick = (event) => {
    setPAnchorEl(event.currentTarget);
  };

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
      instance.logoutRedirect().catch((error) => console.log(error));
  };

  const handleOpenDisclaimer = () => setOpenDisclaimer(true);

  const handleCloseDisclaimer = () => setOpenDisclaimer(false);

  return (
    <div>
      <AppBar position="static" style={{ background: '#000000' }}>
        <Toolbar>
          <Link to="/"><img src={exactech_logo} alt="exactech_logo" sx={{padding: 10}} /></Link>
          <Grid container justify="space-between" spacing={24}>
            <Grid item xs={8}>
            </Grid>
          </Grid>
          <Grid item xs={2} sx={{mr: 2}}>
            <Button variant='contained' color='white' onClick={handleOpenDisclaimer}>
              <Typography sx={{color: '#000'}}>Disclaimer</Typography>
            </Button>
          </Grid>
          <Grid item xs={2}>
            <AuthenticatedTemplate>
              <div>
                  <Button color='inherit' onClick={handlePClick}>
                    {activeAccount ? activeAccount.name : 'Unknown'}
                  </Button>
                  <Menu
                    id='report-menu'
                    anchorEl={PAnchorEl}
                    keepMounted
                    open={Boolean(PAnchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleLogoutRedirect}>Logout</MenuItem>
                  </Menu>
              </div>
            </AuthenticatedTemplate>
            <div/>
            <UnauthenticatedTemplate>
              <div>
                  <Button color='inherit' onClick={handlePClick}>
                    <AccountCircleIcon />
                  </Button>
                  <Menu
                    id='report-menu'
                    anchorEl={PAnchorEl}
                    keepMounted
                    open={Boolean(PAnchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleLoginRedirect}>Login</MenuItem>
                  </Menu>
              </div>
            </UnauthenticatedTemplate>
          </Grid>
        </Toolbar>
      </AppBar>
      <Dialog
        open={openDisclaimer}
        onClose={handleCloseDisclaimer}
        PaperProps={{
          style: {
            backgroundColor: '#11191f',
          },
        }}
      >
       <DialogTitle id="disclaimer-dialog-title">
        <Typography variant='h5' align='center'>
          {"DISCLAIMER"}
        </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="disclaimer-dialog-description" sx={{color: '#ffffff'}} align='center'>
            <Typography variant='h6Lower'>
            This is an experimental application that provides measurement of muscle characteristics using ML, and will be used for improving model outcomes through the incorporation of the HITL (Human In the Loop) technique. 
                <br></br><br></br>
                ⓘ <u>Responses are not validated and may display inaccurate information. Responses do not represent Exactech's views.</u>
              <br></br><br></br>
              © Exactech Inc. 2023. This is a preview.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCloseDisclaimer}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
