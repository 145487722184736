import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import { DataGrid } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';

import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

export const ImagesListView = (props) => {
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiIris.scopes
    });

    const [irisImages, setIrisImages] = useState(props.irisImages);
    const [imageLabels, setImageLabels] = useState(props.imageLabels);
    const [imagesData, setImagesData] = useState([]);
    const [filteredImagesData, setFilteredImagesData] = useState([]);
    const [imageName, setImageName] = useState('');
    const [gender, setGender] = useState('None');
    const [diagnosis, setDiagnosis] = useState('None');
    const [kernel, setKernel] = useState('None');
    const [age, setAge] = useState([0, 100]);
    const [isImageLabelled, setIsImageLabelled] = useState('None');

    const navigate = useNavigate();

    const handleShowImageDetails = (params) => {
      navigate('/iris/imageresult', {state: { subnum: params.row.subnum, feedback: imageLabels }});
    };

    const updateFilteredImagedData = () => {
      let filterData = imagesData;

      if (imageName !== '') {
        filterData = filterData.filter(imageData => 
          imageData.subnum.toUpperCase().includes(imageName.toUpperCase())
        );
      }
      if (gender != 'None') {
        filterData = filterData.filter(imageData => imageData.gender == gender);
      }
      if (diagnosis != 'None') {
        filterData = filterData.filter(imageData => imageData.diagnosis == diagnosis);
      }
      if (kernel != 'None') {
        filterData = filterData.filter(imageData => imageData.kernel == kernel);
      }
      if (age != 'None') {
        filterData = filterData.filter(imageData => imageData.age >= age[0] && imageData.age <= age[1]);
      }
      if (isImageLabelled != 'None') {
        filterData = filterData.filter(imageData => imageData.isLabelled == isImageLabelled);
      }

      setFilteredImagesData(filterData);
    }

    const handleImageNameChange = (event) => {
      setImageName(event.target.value);
    };

    const handleGenderChange = (event) => {
      setGender(event.target.value);
    };

    const handleDiagnosisChange = (event) => {
      setDiagnosis(event.target.value);
    };

    const handleKernelChange = (event) => {
      setKernel(event.target.value);
    };

    const handleAgeChange = (event, newAge) => {
      setAge(newAge);
    };

    const handleIsImageLabelledChange = (event) => {
      setIsImageLabelled(event.target.value);
    };

    useEffect(() => {
      updateFilteredImagedData();
    },[imageName, gender, diagnosis, kernel, age, isImageLabelled]);

    useEffect(() => {
      const imageLabelMap = new Map(
        imageLabels.map(label => [label.image_id, label])
      );
    
      const merged = irisImages.map(image => {
        const feedback = imageLabelMap.get(image.subnum) || [];
        return {
          ...image,
          feedback: Array.isArray(feedback) ? feedback : [feedback],
          isLabelled: Array.isArray(feedback) ? feedback.length > 0 : !!feedback
        };
      });
    
      setImagesData(merged);
      setFilteredImagesData(merged);
    }, [execute, irisImages, imageLabels]);

    const ageSliderMarks = [
      {
        value: age[0],
        label: age[0],
      },
      {
        value: age[1],
        label: age[1],
      },
    ];

    
    function getChipProps(params) {
      const isLabelled = params.value;
      return {
        label: isLabelled ? "Labelled" : "Not Labelled",
        icon: isLabelled ? <VisibilityIcon /> : <VisibilityOffIcon />,
        sx: { 
          mr: 2, 
          bgcolor: isLabelled ? '#0092E3' : 'grey',
          color: 'white',
          '& .MuiChip-icon': {
            order: 1,
            ml: 0,
            mr: 1,
            color: '#F9F9F9',
          },
        }
      };
    }

    const handleDrillDown = (event, row) => {
      event.stopPropagation();
      handleShowImageDetails({ row });
    };

    const imageListColumns = [
      { 
        field: 'subnum', 
        headerName: 'Image Name', 
        flex: 1,
        minWidth: 200,
        valueFormatter: (params) => {
          if (params.value == null) {
            return '';
          }
          return `${params.value.split("/").pop()}`;
        }
      },
      { field: 'kernel', headerName: 'Kernel', flex: 0.75, minWidth: 150 },
      { field: 'gender', headerName: 'Gender', flex: 0.75, minWidth: 150 },
      { field: 'diagnosis', headerName: 'Diagnosis', flex: 0.75, minWidth: 150 },
      { field: 'age', headerName: 'Age', flex: 0.5, minWidth: 100 },
      {
        field: 'isLabelled',
        headerName: "Is Labelled",
        flex: 1,
        minWidth: 200,
        renderCell: (params) => {
          return <Chip {...getChipProps(params)} />;
        },
      },
      { 
        field: 'imageProcesssedAt',
        headerName: 'Processed On',
        flex: 1,
        minWidth: 200,
        valueFormatter: params => moment(params?.value).format("DD/MM/YYYY")
      },
      {
        field: 'drillDown',
        headerName: '',
        width: 70,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <IconButton
            onClick={(event) => handleDrillDown(event, params.row)}
            sx={{ color: '#ffffff' }}
          >
            <ChevronRightIcon />
          </IconButton>
        ),
      }
    ];

   return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Container maxWidth='xl'>
          <Box sx={{ width: 'fit-content', mb: 2 }}>
            <Typography variant='h5' sx={{ mb: 2 }}>
              Segmented Images
            </Typography>
          </Box>
          <br /><br />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FormControl variant="standard">
                <TextField
                  id="image-name-textfield"
                  label="Image Name"
                  variant="standard"
                  onChange={handleImageNameChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon sx={{ color: '#ffffff' }} />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiInput-root': { color: '#ffffff' },
                    '& .MuiInput-underline:before': { borderBottomColor: '#ffffff' },
                    '& .MuiInput-underline:after': { borderBottomColor: '#ffffff' },
                    '& .MuiInputLabel-root': { color: '#ffffff' }, // This will make the label white
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="gender-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-select-label"
                  id="gender-select"
                  value={gender}
                  label="Gender"
                  onChange={handleGenderChange}
                  labelStyle={{ color: "#ffffff" }}
                  sx={{
                    '& .MuiSelect-select': {
                      color: '#ffffff'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'rgba(228, 219, 233, 0.25)'
                    }
                  }}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                  <MenuItem value={'Male'}>Male</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="diagnosis-select-label">Diagnosis</InputLabel>
                <Select
                  labelId="diagnosis-select-label"
                  id="diagnosis-select"
                  value={diagnosis}
                  label="Diagnosis"
                  onChange={handleDiagnosisChange}
                  labelStyle={{ color: "#ffffff" }}
                  sx={{
                    '& .MuiSelect-select': {
                      color: '#ffffff'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'rgba(228, 219, 233, 0.25)'
                    }
                  }}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={'OA'}>OA</MenuItem>
                  <MenuItem value={'RCT'}>RCT</MenuItem>
                  <MenuItem value={'RCA'}>RCA</MenuItem>
                  <MenuItem value={'PTA'}>PTA</MenuItem>
                  <MenuItem value={'FX'}>FX</MenuItem>
                  <MenuItem value={'ON'}>ON</MenuItem>
                  <MenuItem value={'OTH'}>OTH</MenuItem>
                  <MenuItem value={'RA'}>RA</MenuItem>
                  <MenuItem value={'OTH'}>OTH</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="kernel-select-label">Kernel</InputLabel>
                <Select
                  labelId="kernel-select-label"
                  id="kernel-select"
                  value={kernel}
                  label="Kernel"
                  onChange={handleKernelChange}
                  labelStyle={{ color: "#ffffff" }}
                  sx={{
                    '& .MuiSelect-select': {
                      color: '#ffffff'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'rgba(228, 219, 233, 0.25)'
                    }
                  }}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={'BONE'}>BONE</MenuItem>
                  <MenuItem value={'BONEPLUS'}>BONEPLUS</MenuItem>
                  <MenuItem value={'STANDARD'}>STANDARD</MenuItem>
                  <MenuItem value={'FC30'}>FC30</MenuItem>
                  <MenuItem value={'B20s'}>B20s</MenuItem>
                  <MenuItem value={'B31s'}>B31s</MenuItem>
                  <MenuItem value={'B31s'}>B31s</MenuItem>
                  <MenuItem value={'B40s'}>B40s</MenuItem>
                  <MenuItem value={'B60s'}>B60s</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
            <FormControl fullWidth>
                <InputLabel> Is Labelled</InputLabel>
                <Select
                  id="deltoid-label-select"
                  value={isImageLabelled}
                  label="Is Image Labelled"
                  onChange={handleIsImageLabelledChange}
                  labelstyle={{ color: "#ffffff" }}
                  sx={{
                    '& .MuiSelect-select': {
                      color: '#ffffff'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(228, 219, 233, 0.25)'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(228, 219, 233, 0.25)',
                    },
                    '& .MuiSvgIcon-root': {
                        color: 'rgba(228, 219, 233, 0.25)'
                    }
                  }}
                >
                  <MenuItem value={'None'}>None</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1.75}>
              <Typography id="input-slider">
                Age Range
              </Typography>
              <Slider
                getAriaLabel={() => 'Age range'}
                value={age}
                marks={ageSliderMarks}
                onChange={handleAgeChange}
                valueLabelDisplay="auto"
                sx={{
                  '& .MuiSlider-thumb': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-track': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-rail': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-active': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-mark': {
                      color: "#ffffff"
                  },
                  '& .MuiSlider-markLabel': {
                    color: "#ffffff"
                  }
                }}
              />
            </Grid>

          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
            </Grid>
          </Grid>
          <DataGrid
            rows={filteredImagesData}
            columns={imageListColumns}
            getRowId={(row) => row.subnum}
            sx={{
              width: '100%',
              color: '#ffffff',
              boxShadow: 2,
              border: 2,
              borderColor: '#ffffff',
              mt: 2,
              '.MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer'
              },
              '& .MuiDataGrid-menuIconButton': {
                color: '#ffffff'
              },
              '& .MuiButtonBase-root': {
                color: '#ffffff',
              },
              '& .MuiDataGrid-columnHeaders': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                overflow: 'visible',
                lineHeight: '1.43rem',
                whiteSpace: 'normal',
              },
            }}
            onRowClick={handleShowImageDetails}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 15 },
              },
            }}
            pageSizeOptions={[15]}
            autoHeight
            disableColumnMenu
            disableExtendRowFullWidth
            columnBuffer={8}
          />
        </Container>
      </div>
  );
}