import { AuthenticatedTemplate } from "@azure/msal-react";

import Navigation from './Navigation';

import '../styles/App.css';

export const PageLayout = (props) => {
    return (
        <>
            <Navigation />
            <br></br>
            <div>
                {props.children}
            </div>
        </>
    );
};