import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ImageLabelsDialog from './ImageLabelsDialog';
import LabelOrganDialog from './LabelOrganDialog';
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { protectedResources } from "../authConfig";

const organs = ['scapula', 'humerus', 'clavicle', 'deltoid', 'infraspinatus', 'supraspinatus', 'subscapularis'];
const labels = ['No Correction', 'Minor', 'Major'];

const ShoulderCaseLabeling = ({image}) => {
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { error, execute } = useFetchWithMsal({
    scopes: protectedResources.apiIris.scopes
  });
  const [localImage, setLocalImage] = useState(image || { id: '', feedback: [] });
  const [selectedOrgan, setSelectedOrgan] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const [comment, setComment] = useState('');
  const [openLabelDialog, setOpenLabelDialog] = useState(false);
  const [openViewLabelsDialog, setOpenViewLabelsDialog] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    if (isAuthenticated && accounts.length > 0) {
      setUserEmail(accounts[0].username);
    }
  }, [isAuthenticated, accounts]);

  useEffect(() => {
    setLocalImage(image || { id: '', feedback: [] });
  }, [image]);

  const labelImage = (imageid, muscle, label, comment, output_location) => () => {
    let feedbackData = {
      "image_id": imageid,
      "muscle": muscle,
      "feedback": label,
      "comments": comment,
      "environment": "dev",
      "input_location": "NA",
      "model_name": "rotatorcuff",
      "model_version": "v1.1",
      "output_location": output_location,
      "source": "ORCHARD",
      "user_persona": userEmail,
    }
    execute('PUT', protectedResources.apiIris.imageFeedbackEndpoint, feedbackData).then((response) => {
      if (response && response.message === 'success') {
        let updatedFeedbacks = Array.isArray(localImage.feedback) ? [...localImage.feedback] : [];
        const existingFeedbackIndex = updatedFeedbacks.findIndex(feedback => feedback.muscle === selectedOrgan);
        
        if (existingFeedbackIndex !== -1) {
          updatedFeedbacks[existingFeedbackIndex] = feedbackData;
        } else {
          updatedFeedbacks.push(feedbackData);
        }
  
        setLocalImage({ ...localImage, feedback: updatedFeedbacks });
      }
    });
  }

  const handleLabelSubmit = () => {
    labelImage(localImage.subnum, selectedOrgan, selectedLabel, comment, localImage.id)();
    setOpenLabelDialog(false);
    setSelectedOrgan('');
    setSelectedLabel('');
    setComment('');
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item >
          <Button onClick={() => setOpenViewLabelsDialog(true)} variant="outlined">
            View Labels
          </Button>
          <Button onClick={() => setOpenLabelDialog(true)} variant="contained" sx={{ ml: 2 }}>
            Label Shoulder Components
          </Button>
        </Grid>
      </Grid>

      <ImageLabelsDialog 
        open={openViewLabelsDialog} 
        onClose={() => setOpenViewLabelsDialog(false)} 
        image={localImage}
      />

      <LabelOrganDialog
        open={openLabelDialog}
        onClose={() => setOpenLabelDialog(false)}
        selectedOrgan={selectedOrgan}
        setSelectedOrgan={setSelectedOrgan}
        selectedLabel={selectedLabel}
        setSelectedLabel={setSelectedLabel}
        comment={comment}
        setComment={setComment}
        organs={organs}
        labels={labels}
        onSubmit={handleLabelSubmit}
      />
    </div>
  );
};

export default ShoulderCaseLabeling;