import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const LabelOrganDialog = ({
  open,
  onClose,
  selectedOrgan,
  setSelectedOrgan,
  selectedLabel,
  setSelectedLabel,
  comment,
  setComment,
  organs,
  labels,
  onSubmit,
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#1E2A32',
          color: '#F9F9F9',
        },
      }}
    >
      <DialogTitle sx={{ 
        padding: '20px 24px',
        fontSize: '1.25rem',
        fontWeight: 'bold',
      }}>
        Label Shoulder Components
      </DialogTitle>
      <DialogContent sx={{ padding: '24px' }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ minWidth: '80px', mr: 2 }}>Organ:</Typography>
              <Select
                value={selectedOrgan}
                onChange={(e) => setSelectedOrgan(e.target.value)}
                fullWidth
                sx={{ 
                  color: '#F9F9F9',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(249, 249, 249, 0.3)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(249, 249, 249, 0.5)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#F9F9F9',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#F9F9F9',
                  },
                }}
              >
                {organs.map(organ => (
                    <MenuItem key={organ} value={organ}>
                        {organ.charAt(0).toUpperCase() + organ.slice(1).toLowerCase()}
                    </MenuItem>
                    ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ minWidth: '80px', mr: 2 }}>Label:</Typography>
              <Select
                value={selectedLabel}
                onChange={(e) => setSelectedLabel(e.target.value)}
                fullWidth
                sx={{ 
                  color: '#F9F9F9',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(249, 249, 249, 0.3)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(249, 249, 249, 0.5)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#F9F9F9',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#F9F9F9',
                  },
                }}
              >
                {labels.map(label => (
                  <MenuItem key={label} value={label}>{label}</MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Comment"
              multiline
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  color: '#F9F9F9',
                  '& fieldset': {
                    borderColor: 'rgba(249, 249, 249, 0.3)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(249, 249, 249, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#F9F9F9',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(249, 249, 249, 0.7)',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#F9F9F9',
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ 
        padding: '16px 24px', 
      }}>
        <Button 
          onClick={onClose}
          sx={{ 
            color: '#F9F9F9', 
            '&:hover': { backgroundColor: 'rgba(249, 249, 249, 0.08)' }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={onSubmit} 
          variant="contained" 
          sx={{ 
            backgroundColor: '#0092E3',
            color: '#F9F9F9',
            '&:hover': { backgroundColor: '#007BB8' }
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LabelOrganDialog;