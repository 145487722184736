import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { loginRequest, appRoles } from "../authConfig";

import '../styles/App.css';

export const RouteGuard = ({ ...props }) => {
    const location = useLocation();
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);

    const currentAccount = instance.getActiveAccount();

    const authRequest = {
        ...loginRequest,
    };

    const onLoad = async () => {
        if (currentAccount && currentAccount.idTokenClaims['roles']) {

            let intersection = props.roles
                .filter((role) => currentAccount.idTokenClaims['roles'].includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    };

    useEffect(() => {
        onLoad();
    }, [instance, currentAccount]);

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            {isAuthorized ? (
                <div>{props.children}</div>
            ) : (
                <div className="data-area-div">
                    <Alert severity="info" variant="filled" >
                    <Typography variant="h5">      
                        You are unauthorized to view this content. Please reach out to the administrator to assign you 
                        {(() => {
                            switch (location.pathname) {
                            case '/iris/home':
                                return <span> {' '} {appRoles.IrisUser} role. </span>
                            case '/iris/deltoidimages':
                                return <span> {' '} {appRoles.IrisUser} role. </span>
                            case '/iris/segmentationresults':
                                return <span> {' '} {appRoles.IrisUser} role. </span>
                            case '/sage/home':
                                return <span> {' '} {appRoles.SageUser} role. </span>
                            case '/predictplus/home':
                                return <span> {' '} {appRoles.PredictPlusUser} role. </span>
                            default:
                                return <span> {' '} {appRoles.Admin} role. </span>
                            }
                        })()}
                        </Typography>
                    </Alert>
                </div>
            )}

        </MsalAuthenticationTemplate>
    );
};
