import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: 'c3efd73f-751f-4342-a9c7-a187d595fa63',
        authority: 'https://login.microsoftonline.com/9adaab1d-bd58-40a7-9255-69ab5f6c3189',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        clientCapabilities: ['CP1'],
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

export const protectedResources = {
    apiIris: {
        segmentationResultsEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/segmentationResults',
        imageDownloadLinksEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/processedImagesLinks',
        imageFeedbackEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/imageFeedback',
        latestImageFeedbackEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/latestimagefeedback',
        imageMetadataEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/imageMetadata',
        imageMetadataRangeEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/imageMetadataRange',
        irisImagesEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/irisimages',
        irisImageDetailsEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/irisimagedetails',
        feedbackEndpoint: 'https://testdeploynodeast.azurewebsites.net/iris/feedback',
        scopes: ['api://c3efd73f-751f-4342-a9c7-a187d595fa63/access_via_approle_assignments'],
    }
};

export const loginRequest = {
    scopes: [...protectedResources.apiIris.scopes],
};

export const appRoles = {
    IrisUser: 'IrisUser',
    SageUser: 'SageUser',
    PredictPlusUser: 'PredictPlusUser',
    Admin: 'WorkspaceAdmin'
}
