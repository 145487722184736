import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { ImagesListView } from './ImagesListView';
import { loginRequest, protectedResources } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';


const SegmentationResultsContent = () => {
    const { error, execute } = useFetchWithMsal({
        scopes: protectedResources.apiIris.scopes,
    });

    const [irisImages, setIrisImages] = useState(null);
    const [imageLabels, setImageLabels] = useState(null);

    useEffect(() => {
        if (!irisImages) {
            execute('GET', protectedResources.apiIris.irisImagesEndpoint).then((response) => {
                setIrisImages(response);
            });
        }
    }, [execute, irisImages])

    useEffect(() => {
        if (!imageLabels) {
            execute('GET', protectedResources.apiIris.latestImageFeedbackEndpoint).then((response) => {
                setImageLabels(response);
            });
        }
    }, [execute, imageLabels])

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return <>{
        (irisImages && imageLabels) ? 
        <ImagesListView 
            imageLabels={imageLabels} 
            irisImages={irisImages}
        /> :
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <CircularProgress size="10rem" sx={{ color: '#0092E3' }} />
        </Box>
        }</>;
};

export const SegmentationResults = () => {
    const authRequest = {
        ...loginRequest,
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            <SegmentationResultsContent />
        </MsalAuthenticationTemplate>
    );
};
