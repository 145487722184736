import React, { useEffect, useRef, useState, useCallback } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';
import Hammer from 'hammerjs';
import JSZip from 'jszip';
import * as nifti from 'nifti-reader-js';
import pako from 'pako';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.init();
cornerstoneTools.addTool(cornerstoneTools.ZoomTool);

const flipVertical = (data, width, height) => {
  const flipped = new Uint8Array(data.length);
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      flipped[y * width + x] = data[(height - 1 - y) * width + x];
    }
  }
  return flipped;
};

const calculateNiftiSliceIndex = (dicomImage, niftiHeader) => {
  const dicomSlicePositionString = dicomImage.data.string('x00200032');
  if (!dicomSlicePositionString) {
    console.error('Missing Image Position (Patient) in DICOM');
    return 0;
  }
  const dicomSlicePosition = parseFloat(dicomSlicePositionString.split('\\')[2]);
  if (isNaN(dicomSlicePosition)) {
    console.error('Invalid Image Position (Patient) in DICOM');
    return 0;
  }
  const niftiSlicePosition = (dicomSlicePosition - niftiHeader.qoffset_z) / niftiHeader.pixDims[3];
  const sliceIndex = Math.round(niftiSlicePosition);
  
  return Math.max(0, Math.min(sliceIndex, niftiHeader.dims[3] - 1));
};

const sortDicomFiles = (files) => {
  return files.sort((a, b) => {
    const aPosition = a.imagePositionPatient ? parseFloat(a.imagePositionPatient.split('\\')[2]) : null;
    const bPosition = b.imagePositionPatient ? parseFloat(b.imagePositionPatient.split('\\')[2]) : null;
    
    if (aPosition !== null && bPosition !== null) {
      return aPosition - bPosition;
    }

    if (a.sliceLocation && b.sliceLocation) {
      return parseFloat(a.sliceLocation) - parseFloat(b.sliceLocation);
    }

    return parseInt(a.instanceNumber, 10) - parseInt(b.instanceNumber, 10);
  });
};

const sortByFilename = (files) => {
  return files.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
  });
};

const checkAndReverse = (imageUrls) => {
  if (imageUrls.length > 1) {
      const firstPos = parseFloat(imageUrls[0].imagePositionPatient?.split('\\')[2]);
      const lastPos = parseFloat(imageUrls[imageUrls.length - 1].imagePositionPatient?.split('\\')[2]);
      if (!isNaN(firstPos) && !isNaN(lastPos) && firstPos > lastPos) {
      console.log('Detected reverse order, correcting...');
      return imageUrls.reverse();
      }
  }
  return imageUrls;
};

const highlightBoundaries = (data, width, height, color) => {
  const highlighted = new Uint8ClampedArray(width * height * 4);
  const directions = [[-1, 0], [1, 0], [0, -1], [0, 1]];

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = y * width + x;
      const pixelIndex = index * 4;

      if (data[index] > 0) {
        let isBoundary = false;
        for (const [dx, dy] of directions) {
          const nx = x + dx;
          const ny = y + dy;
          if (nx >= 0 && nx < width && ny >= 0 && ny < height) {
            if (data[ny * width + nx] === 0) {
              isBoundary = true;
              break;
            }
          }
        }

        if (isBoundary) {
          highlighted[pixelIndex] = color.boundaryR;
          highlighted[pixelIndex + 1] = color.boundaryG;
          highlighted[pixelIndex + 2] = color.boundaryB;
          highlighted[pixelIndex + 3] = 255;
        } else {
          highlighted[pixelIndex] = color.r;
          highlighted[pixelIndex + 1] = color.g;
          highlighted[pixelIndex + 2] = color.b;
          highlighted[pixelIndex + 3] = color.a;
        }
      } else {
        highlighted[pixelIndex + 3] = 0;
      }
    }
  }
  return highlighted;
};

const DicomNiftiViewer = ({ zipUrl, niftiUrls }) => {
  const elementRef = useRef(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [niftiData, setNiftiData] = useState([]);
  const [niftiHeaders, setNiftiHeaders] = useState([]);
  const [intensity, setIntensity] = useState(2);

  const applyIntensity = (intensityValue) => {
    const element = elementRef.current;
    if (!element) return;

    const viewport = cornerstone.getViewport(element);
    if (viewport) {
      const windowWidth = 400 * intensityValue;
      const windowCenter = 40 * intensityValue;

      const baseLayer = cornerstone.getLayer(element, 'dicomBase');
      if (baseLayer && baseLayer.viewport) {
        baseLayer.viewport.voi = { windowWidth, windowCenter };
      }

      viewport.voi = { windowWidth, windowCenter };
      cornerstone.setViewport(element, viewport);

      cornerstone.updateImage(element);
    }
  };

  const handleIntensityChange = (event) => {
    const newIntensity = parseFloat(event.target.value);
    setIntensity(newIntensity);
    applyIntensity(newIntensity);
  };

  const prepareNiftiOverlays = useCallback((dicomImage) => {
    console.log('Preparing NIFTI overlays');

    if (!niftiData.length || !niftiHeaders.length || !dicomImage) {
      console.log('Skipping NIFTI overlays due to missing data');
      return [];
    }

    return niftiData.map((data, index) => {
      const header = niftiHeaders[index];
      const { color, boundaryColor } = niftiUrls[index];

      if (dicomImage.width !== header.dims[1] || dicomImage.height !== header.dims[2]) {
        console.error(`Dimension mismatch for ${niftiUrls[index].name}!`);
        return null;
      }

      const sliceIndex = calculateNiftiSliceIndex(dicomImage, header);
      const sliceSize = header.dims[1] * header.dims[2];
      const sliceData = new Uint8Array(data.slice(sliceIndex * sliceSize, (sliceIndex + 1) * sliceSize));

      if (sliceData.every(value => value === 0)) {
        console.log(`No non-zero values in NIFTI slice for overlay ${index}`);
        return null;
      }

      let transformedSliceData = flipVertical(sliceData, header.dims[1], header.dims[2]);

      const coloredSliceData = highlightBoundaries(transformedSliceData, header.dims[1], header.dims[2], {
        r: color.r,
        g: color.g,
        b: color.b,
        a: color.a,
        boundaryR: boundaryColor.r,
        boundaryG: boundaryColor.g,
        boundaryB: boundaryColor.b
      });

      return {
        imageId: `niftiOverlay_${index}`,
        minPixelValue: 0,
        maxPixelValue: 255,
        slope: 1.0,
        intercept: 0,
        windowCenter: 127,
        windowWidth: 256,
        getPixelData: () => coloredSliceData,
        rows: header.dims[2],
        columns: header.dims[1],
        height: header.dims[2],
        width: header.dims[1],
        color: true,
        rgba: true,
        columnPixelSpacing: dicomImage.columnPixelSpacing,
        rowPixelSpacing: dicomImage.rowPixelSpacing,
        sizeInBytes: coloredSliceData.length
      };
    }).filter(overlay => overlay !== null);
  }, [niftiData, niftiHeaders, currentIndex, niftiUrls, imageUrls.length]);

  const loadImage = useCallback(async (index) => {
    if (imageUrls.length > 0 && elementRef.current) {
      try {
        console.log(`Loading DICOM image at index ${index}`);
        const image = await cornerstone.loadAndCacheImage(imageUrls[index]);

        const currentViewport = cornerstone.getViewport(elementRef.current);
        const currentZoom = currentViewport ? currentViewport.scale : 1;
        const currentTranslation = currentViewport ? currentViewport.translation : { x: 0, y: 0 };

        cornerstone.getLayers(elementRef.current).forEach(layer => {
          cornerstone.removeLayer(elementRef.current, layer.layerId);
        });

        cornerstone.addLayer(elementRef.current, image, { 
          opacity: 1, 
          name: 'dicomBase',
          viewport: {
            scale: currentZoom,
            translation: currentTranslation,
            voi: {
              windowWidth: 400 * intensity,
              windowCenter: 40 * intensity
            }
          }
        });

        const niftiOverlays = prepareNiftiOverlays(image);
        niftiOverlays.forEach((overlay, index) => {
          if (overlay) {
            console.log(`Adding NIFTI overlay ${index}`);
            cornerstone.addLayer(elementRef.current, overlay, { 
              opacity: 0.5, 
              name: `niftiOverlay_${index}`,
              viewport: {
                scale: currentZoom,
                translation: currentTranslation
              }
            });
          }
        });

        const viewport = cornerstone.getViewport(elementRef.current);
        viewport.scale = currentZoom;
        viewport.translation = currentTranslation;
        cornerstone.setViewport(elementRef.current, viewport);

        applyIntensity(intensity);

        cornerstone.updateImage(elementRef.current);
        console.log('Image updated with layers, zoom, and translation maintained');
      } catch (error) {
        console.error(`Error loading or displaying image at index ${index}:`, error);
      }
    }
  }, [imageUrls, prepareNiftiOverlays, intensity]);

  const handleSliderChange = (event) => {
    const newIndex = parseInt(event.target.value, 10);
    setCurrentIndex(newIndex);
    loadImage(newIndex);
  };

  useEffect(() => {
    cornerstone.enable(elementRef.current);
    cornerstoneTools.addToolForElement(elementRef.current, cornerstoneTools.ZoomTool);
    cornerstoneTools.setToolActiveForElement(elementRef.current, 'Zoom', { mouseButtonMask: 1 });

    const loadZipAndImages = async () => {
      try {
        console.log('Fetching DICOM .gz file from:', zipUrl);
        const response = await fetch(zipUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok while fetching DICOM .gz file: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        console.log('Fetched DICOM file successfully.');
        console.log('Decompressing and loading DICOM .gz file...');

        const zip = await JSZip.loadAsync(arrayBuffer);
        const dicomFiles = [];
        zip.forEach((relativePath, file) => {
          if (relativePath.startsWith('00-dicom/') && relativePath.endsWith('.dcm')) {
            dicomFiles.push(file);
          }
        });

        if (dicomFiles.length === 0) {
          console.error('No DICOM files found in the ZIP archive.');
          return;
        }
        let sortedDicomFiles = sortByFilename(dicomFiles);
        const loadedImageUrls = await Promise.all(
          sortedDicomFiles.map(async (file, index) => {
            try {
              const dicomBlob = await file.async('blob');
              const arrayBuffer = await dicomBlob.arrayBuffer();
              const dicomData = dicomParser.parseDicom(new Uint8Array(arrayBuffer));
        
              const instanceNumber = dicomData.string('x00200013');
              const sliceLocation = dicomData.string('x00201041');
              const imagePositionPatient = dicomData.string('x00200032');
        
              const url = URL.createObjectURL(dicomBlob);
              return {
                url: `wadouri:${url}`,
                instanceNumber,
                sliceLocation,
                imagePositionPatient,
                fileName: file.name
              };
            } catch (error) {
              console.error(`Error processing file ${index}:`, error);
              return null;
            }
          })
        );

        console.log('Decompressed and loaded DICOM file successfully.');

        const validImageUrls = loadedImageUrls.filter(item => item !== null);
        const correctedImageUrls = checkAndReverse(validImageUrls);
        const sortedValidImageUrls = sortDicomFiles(correctedImageUrls);

        const finalImageUrls = sortedValidImageUrls.map(item => item.url);
        setImageUrls(finalImageUrls);

        if (finalImageUrls.length > 0) {
          loadImage(0);
        } else {
          console.error('No valid DICOM images found to display.');
        }
      } catch (error) {
        console.error('Error loading DICOM ZIP archive:', error);
      }
    };

    loadZipAndImages();

    return () => {
      cornerstone.disable(elementRef.current);
      imageUrls.forEach((url) => URL.revokeObjectURL(url.replace('wadouri:', '')));
    };
  }, [zipUrl]);

  useEffect(() => {
    console.log(`Current index changed to: ${currentIndex}`);
  }, [currentIndex]);

  useEffect(() => {
    if (elementRef.current && imageUrls.length > 0) {
      loadImage(currentIndex);
    }
  }, [intensity, loadImage, currentIndex, imageUrls]);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const zoomHandler = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        const viewport = cornerstone.getViewport(element);
        if (event.deltaY < 0) {
          viewport.scale += viewport.scale * 0.1;
        } else {
          viewport.scale -= viewport.scale * 0.1;
        }
        cornerstone.setViewport(element, viewport);
      }
    };

    element.addEventListener('wheel', zoomHandler);

    return () => {
      element.removeEventListener('wheel', zoomHandler);
    };
  }, []);

  useEffect(() => {
    const loadNiftiMasks = async () => {
      try {
        const loadedData = [];
        const loadedHeaders = [];

        for (const { url } of niftiUrls) {
          console.log('Fetching NIFTI mask .nii.gz file from:', url);
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error(`Network response was not ok while fetching NIFTI .nii.gz mask file: ${response.statusText}`);
          }
          const arrayBuffer = await response.arrayBuffer();
          const decompressedData = pako.inflate(arrayBuffer);

          const inflatedBuffer = nifti.Utils.toArrayBuffer(decompressedData);
          if (nifti.isNIFTI(inflatedBuffer)) {
            const header = nifti.readHeader(inflatedBuffer);
            const image = nifti.readImage(header, inflatedBuffer);
            loadedHeaders.push(header);
            loadedData.push(image);
            console.log('NIFTI image loaded');
          } else {
            console.error('Not a valid NIFTI file');
          }
        }

        setNiftiHeaders(loadedHeaders);
        setNiftiData(loadedData);
      } catch (error) {
        console.error('Error loading NIFTI files:', error);
      }
    };

    loadNiftiMasks();
  }, [niftiUrls]);

  return (
    <div style={{ height: '512px', position: 'relative' }}>
      <div
        ref={elementRef}
        style={{ width: '100%', height: '100%', backgroundColor: 'black' }}
      />
      <div style={{ textAlign: 'center' }}>
        <input
          type="range"
          min="0"
          max={imageUrls.length - 1}
          value={currentIndex}
          onChange={handleSliderChange}
          style={{ width: '100%' }}
        />
      </div>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginTop: '10px'
      }}>
        <label style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
          Intensity
        </label>
        <input
          type="range"
          min="0.1"
          max="5"
          step="0.1"
          value={intensity}
          onChange={handleIntensityChange}
          style={{ width: '50%' }}
        />
      </div>
    </div>
  );
};

export default DicomNiftiViewer;